import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'



export default function Humanitarian(props) {



useEffect(() => {


}, [])


var href = window.location.href.split("/")[3]
    return (
      <div className="main-body-2 fade-in">

            <div className={href !== "" ? "navbar" : "navbar fade-in2"}>

            <Link className="logo-link" to={`/`}> 
          <div className="navbar-left shadow-drop-2-center">
              <div className={href == "" ? "logo black" : "logo white-gradient "}>
              <div className={href == "" ? "gradient-textb font-14 abs-2" : "gradient-text font-14 abs-2"}>Studio Thirty Two</div>
              <div className={href == "" ? "gradient-textb font-14 abs-3" : "gradient-text font-14 abs-3"}>
                  32
              </div>
              </div>
          </div>
          </Link>
      

          <div className="middle-bar">



  <Link to={`/causes`}>
  <div className={href == "" ? "black nav-btn smaller uppercase bolder text-black" : "white-gradient nav-btn smaller uppercase bolder gradient-text2 text-black pad-12"}>
      Causes
  </div>
  </Link>



  <Link to={`/childlabor`}>
  <div className={href == "" ? "black nav-btn smaller uppercase bolder  text-black" : "white-gradient nav-btn smaller uppercase bolder gradient-text2 text-black pad-12"}>
     Child Labor
  </div>
  </Link>
</div>
</div>

    

               <div className="main-page">
                   
          
          <div className="mobile-front">
            <img className="mobile-image-2" src={`https://lorranlima.s3.us-east-2.amazonaws.com/humanitarianpage.jpg`}></img>
         
          </div>

          <div className="desktop-front">
          <img className="desktop-image-2" src={`https://lorranlima.s3.us-east-2.amazonaws.com/humanitarianpage.jpg`}></img>
         
          </div>
          </div>

<div className="pad-topp">
          <div className="image-overlay-slogan-2 white uppercase bold-2 gradient-text boldest">
            <div className="shadow-drop-2-center pad-20 gradient-text boldest">
              <div className="gradient-texta boldest">
                  <div className="boldest">
                  The Art of Storytelling
                  </div>
                  </div>
            </div>
            </div>
          </div> 
          </div>
        );
    }